<template>
    <div class="image-block" ref="target" v-observe-visibility="visibilityChanged">
      <img
        :src="require(`@/assets/${imagePath}`)"
        :srcset="require(`@/assets/${imagePath}`) + ' 1x, ' + require(`@/assets/${imagePath2x}`) + ' 2x'"/>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
// import Parallax from 'vue-parallaxy'
// import VueResponsiveImage from 'vue-responsive-image'
import VueObserveVisibility from 'vue-observe-visibility'
import { debounce } from 'debounce'
Vue.use(VueObserveVisibility)

export default {
  name: 'ImageBlock',
  components: {
    // Parallax
  },
  props: {
    imagePath: String
  },
  data () {
    return {
      windowHeight: document.body.scrollHeight,
      modifier: 2.5
    }
  },
  created () {
    // On create load you resource
    this.loadResource()
    this.getImagePath2x()
    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED']),
    getImagePath2x () {
      const arr = this.imagePath.split('.')
      arr[arr.length - 1] = '@2x.' + arr[arr.length - 1]
      this.imagePath2x = arr.join().replace(',', '')
    },
    updateScroll () {
      if (!this.isVisible) return
      const rect = this.$refs.target.getBoundingClientRect()
      const y = rect.top + rect.height
      const total = this.windowHeight + rect.height
      const percentage = Math.round(y * 100 / total)
      // const shift = percentage
      const image = this.$refs.target.querySelector('img')
      // image.style.objectPosition = '0 ' + (shift * this.modifier) + '%'
      image.style.objectPosition = '0 ' + (percentage) + '%'
    },
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      this.observer = entry
    }
  },
  mounted () {
    window.addEventListener('scroll', debounce(this.updateScroll, 5))
  }
}
</script>

<style lang="scss">
@import './ImageBlock.scss';
</style>
