<template>
  <div class="download-pack">
    <div class="content">
      <div class="content__left">
        <h3 class="ta-left red">GET INVOLVED</h3>
        <h3 class="ta-right black">AND SUPPORT THE CAUSE</h3>
        <p>Download our social media pack and show your support</p>
        <div class="cta-wrapper">
          <a href="#" class="btn btn--orange">
            Download Pack
          </a>
          <a href="#" class="btn btn--black">
            Journalist? Download the media kit
          </a>
        </div>
      </div>
      <div class="content__right">
        <img
          :src="require('@/assets/im_download_image.jpg')"
          :srcset="require('@/assets/im_download_image.jpg') + ' 1x, ' + require('@/assets/im_download_image@2x.jpg') + ' 2x'"
          class="desktop"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import VueResponsiveImage from 'vue-responsive-image'

export default {
  name: 'DownloadPack',
  props: {
    tickerText: String
  },
  created () {
    // On create load you resource
    this.loadResource()
    // this.getImagePath2x()
    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    getImagePath2x () {
      const arr = this.imagePath.split('.')
      arr[arr.length - 1] = '@2x.' + arr[arr.length - 1]
      this.imagePath2x = arr.join().replace(',', '')
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED'])
  }
}
</script>

<style lang="scss">
@import './DownloadPack.scss';
</style>
