<template>
  <footer>
    <div class="content">
      <p>This is public service announcement by <a href="https://www.weareimpero.com/" target="_blank">Impero</a> - the creative agency for impatient brands. </p>
      <p>A shout out to our generous photographers Noah Fecks (<a href="https://www.instagram.com/noahfecksisawesome/" target="_blank">@noahfecksisawesome</a>), Aaron Graubart (<a href="https://www.instagram.com/aarongraubart/" target="_blank">@aarongraubart</a>) and Chloe Hardwick (<a href="https://www.instagram.com/Chloe.hardwick/" target="_blank">@Chloe.hardwick</a>)</p>
      <p>For more information call us: +44 7711 375405 but not between 11:30 and 4:45, we never take calls at lunch.</p>
      <a href="https://www.weareimpero.com/" target="_blank">
        <img
          :src="require('@/assets/im_impero_logo.png')"
          :srcset="require('@/assets/im_impero_logo.png') + ' 1x, ' + require('@/assets/im_impero_logo@2x.png') + ' 2x'"
          class="footer__logo"/>
      </a>
    </div>
  </footer>
</template>

<script>
import { mapActions } from 'vuex'
// import VueResponsiveImage from 'vue-responsive-image'

export default {
  name: 'Footer',
  props: {
    tickerText: String
  },
  created () {
    // On create load you resource
    this.loadResource()
    // this.getImagePath2x()
    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    getImagePath2x () {
      const arr = this.imagePath.split('.')
      arr[arr.length - 1] = '@2x.' + arr[arr.length - 1]
      this.imagePath2x = arr.join().replace(',', '')
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED'])
  }
}
</script>

<style lang="scss">
@import './Footer.scss';
</style>
