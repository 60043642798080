<template>
  <div class="explain">
    <div class="content">
      <h4>Never before has the hospitality industry needed the business community quite so much.<br>
      <span>It's time we restored a once fine British tradition</span>; <span>the long lunch</span>.<br>
      <br>
      Let us explain...</h4>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import VueResponsiveImage from 'vue-responsive-image'

export default {
  name: 'Explain',
  props: {
    msg: String
  },
  created () {
    // On create load you resource
    this.loadResource()

    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED'])
  }
}
</script>

<style lang="scss">
@import './Explain.scss';
</style>
