<template>
  <nav :class="scrollingDown ? 'nav' : 'nav visible'">
    <ul>
      <li><a href="#" v-scroll-to="{el:'.joinus', onStart: onScrollTo}">About</a></li>
      <li><a href="#" v-scroll-to="{el:'.generator', onStart: onScrollTo}">Excuse Generator</a></li>
      <!-- <li><a href="#" v-scroll-to="{el:'.signthepetition', onStart: onScrollTo}">The Petition</a></li> -->
      <li><a href="#" v-scroll-to="{el:'.download-pack', onStart: onScrollTo}">Get Involved</a></li>
      <li><a href="#" v-scroll-to="{el:'footer', onStart: onScrollTo}">Contact</a></li>
    </ul>
    <a href="#" class="nav__hamburger" @click="toggleNav">
      <span></span>
      <span></span>
      <span></span>
    </a>
  </nav>
</template>

<script>
import Vue from 'vue'
import VModal from 'vue-js-modal'
import { mapActions } from 'vuex'
import { debounce } from 'debounce'
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)
// import VueResponsiveImage from 'vue-responsive-image'

Vue.use(VModal)

export default {
  name: 'Nav',
  props: {
    msg: String
  },
  created () {
    // On create load you resource
    this.loadResource()

    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  data () {
    return {
      lastScrollTop: 0,
      scrollingDown: false
    }
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED']),
    openModal () {
      this.$modal.show('promovid')
    },
    toggleNav () {
      this.$el.classList.toggle('nav--open')
    },
    onScrollTo () {
      this.$el.classList.toggle('nav--open')
    },
    updateScroll (e) {
      var st = window.pageYOffset || document.documentElement.scrollTop
      if (st > this.lastScrollTop) {
        this.scrollingDown = true
      } else {
        this.scrollingDown = false
      }
      this.lastScrollTop = st <= 0 ? 0 : st
    }
  },
  mounted () {
    window.addEventListener('scroll', debounce(this.updateScroll.bind(this), 5))
  }
}
</script>

<style lang="scss">
@import './Nav.scss';
</style>
