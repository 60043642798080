<template>
  <div class="scroll-feature" ref="target" v-observe-visibility="visibilityChanged">
    <div class="scroll-feature__row">
      <div class="scroll-feature__contents">
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_01.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_01.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_01@2x.jpg') + ' 2x'"/>
        <span>Bring</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_02.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_02.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_02@2x.jpg') + ' 2x'"/>
        <span>Back</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_03.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_03.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_03@2x.jpg') + ' 2x'"/>
        <span>The</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_04.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_04.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_04@2x.jpg') + ' 2x'"/>
        <span>Long</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_05.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_05.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_05@2x.jpg') + ' 2x'"/>
        <span>Lunch</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_06.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_06.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_06@2x.jpg') + ' 2x'"/>
      </div>
    </div>
    <div class="scroll-feature__row">
      <div class="scroll-feature__contents">
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_07.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_07.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_07@2x.jpg') + ' 2x'"/>
        <span>Bring</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_08.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_08.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_08@2x.jpg') + ' 2x'"/>
        <span>Back</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_09.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_09.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_09@2x.jpg') + ' 2x'"/>
        <span>The</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_10.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_10.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_10@2x.jpg') + ' 2x'"/>
        <span>Long</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_11.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_11.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_11@2x.jpg') + ' 2x'"/>
        <span>Lunch</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_12.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_12.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_12@2x.jpg') + ' 2x'"/>
      </div>
    </div>
    <div class="scroll-feature__row">
      <div class="scroll-feature__contents">
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_13.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_13.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_13@2x.jpg') + ' 2x'"/>
        <span>Bring</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_14.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_14.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_14@2x.jpg') + ' 2x'"/>
        <span>Back</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_15.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_15.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_15@2x.jpg') + ' 2x'"/>
        <span>The</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_16.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_16.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_16@2x.jpg') + ' 2x'"/>
        <span>Long</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_17.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_17.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_17@2x.jpg') + ' 2x'"/>
        <span>Lunch</span>
        <img
          :src="require('@/assets/scrollfeature/im_scrollfeature_18.jpg')"
          :srcset="require('@/assets/scrollfeature/im_scrollfeature_18.jpg') + ' 1x, ' + require('@/assets/scrollfeature/im_scrollfeature_18@2x.jpg') + ' 2x'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import gsap from 'gsap'
import { mapActions } from 'vuex'
import { debounce } from 'debounce'

Vue.use(VueObserveVisibility)

export default {
  name: 'ScrollFeature',
  props: {
    msg: String
  },
  data () {
    return {
      rows: null,
      windowHeight: document.body.scrollHeight,
      lastScrollTop: window.pageYOffset || document.documentElement.scrollTop,
      speed: 0.8
    }
  },
  created () {
    // On create load you resource
    this.loadResource()

    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED']),
    percentVisible () {
      const rect = this.$refs.target.getBoundingClientRect()
      const visibleHeight = this.windowHeight + rect.height
      const yPos = this.windowHeight - rect.top
      return yPos * 100 / visibleHeight
    },
    isScrollingDown () {
      const st = window.pageYOffset || document.documentElement.scrollTop
      const scrollingDown = st > this.lastScrollTop
      this.lastScrollTop = st <= 0 ? 0 : st
      return scrollingDown
    },
    updateScroll () {
      if (!this.isVisible) return
      const percentage = this.percentVisible()
      const scrollingDown = this.isScrollingDown()
      this.rows = this.$refs.target.children
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i]
        const isOdd = i % 2 === 0
        this.animate(row, isOdd, percentage, scrollingDown)
      }
    },
    animate (elem, isOdd, percentage, scrollingDown) {
      const rect = elem.firstChild.getBoundingClientRect()
      // const x = (rect.width / 100 * (percentage * this.speed) - (rect.width / 2))
      const x = (rect.width / 100 * (percentage * this.speed))
      gsap.to(elem, { duration: 0.2, x: isOdd ? -x : x })
    },
    setupRows () {
      this.rows = this.$refs.target.children
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i]
        row.firstChild.innerHTML = row.firstChild.innerHTML + row.firstChild.innerHTML
        // const isOdd = i % 2 === 0
      }
    },
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      this.observer = entry
      // console.log(entry)
    }
  },
  mounted () {
    this.setupRows()
    window.addEventListener('scroll', debounce(this.updateScroll, 5))
    // window.addEventListener('scroll', this.updateScroll)
  }
}
</script>

<style lang="scss">
@import './ScrollFeature.scss';
</style>
