var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
  callback: _vm.visibilityChanged,
  intersection: {
    threshold: 0.5,
  },
}),expression:"{\n  callback: visibilityChanged,\n  intersection: {\n    threshold: 0.5,\n  },\n}"}],staticClass:"generator"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"content__right"},[_c('div',{staticClass:"generator__app"},[_c('h4',{ref:"wrapper"},[_c('span',{ref:"excuse"}),_c('span',{ref:"verb",staticClass:"with"})]),_c('div',{staticClass:"generator__footer"},[_c('img',{attrs:{"src":require('@/assets/im_bringback_logo.svg')}})])]),_c('a',{staticClass:"btn btn--blue generator__cta",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.generate.apply(null, arguments)}}},[_vm._v(" Order another reason ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__left"},[_c('h3',[_c('span',[_vm._v("WTF is my reason for")]),_c('br'),_vm._v(" a Long Lunch TODAY"),_c('span',[_vm._v("?")])]),_c('p',[_vm._v("Financial Directors are such Coq au Vin blockers, but we can help. Here’s a million and one reasons why you need a Long Lunch now.")])])
}]

export { render, staticRenderFns }