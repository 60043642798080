<template>
  <div class="joinus">
    <div class="content">
      <div class="content--top">
        <h3 class="ta-left pink">Join our<br>
        movement to</h3>
      </div>
      <div class="content--body">
        <div class="content--body-left">
          <h3 class="ta-right">Bring Back the<br>
          Long Lunch</h3>
        </div>
        <div class="content--body-right">
          <p>It used to fill the bistros, pubs, and restaurants with guaranteed clientele - but over the past 20 years, it’s fallen out of fashion. Then 18 months of pandemic came along...<br>
          <br>
          <span class="pink">Enough is enough.</span><br>
          Let's support UK hospitality.<br>
          Let's bring back the long lunch.<br>
          <br>
          Put your calculators away.<br>
          Tell your FD a porky.<br>
          And swap your meal deal for the real
          deal.<br>
          UK Hospitality needs the business community like never before. And in case you've forgotten, it’s great for team morale too.<br>
          <br>
          To join the movement, all you need to do is open the expense account, extend a client invitation, uncork a bottle or two and tell everyone you work with to do the same.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import VueResponsiveImage from 'vue-responsive-image'

export default {
  name: 'JoinUs',
  props: {
    msg: String
  },
  created () {
    // On create load you resource
    this.loadResource()

    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED'])
  }
}
</script>

<style lang="scss">
@import './JoinUs.scss';
</style>
