<template>
  <div class="hero">
    <modal
      name="promovid"
      height="auto"
      width="80%"
      @opened="resizeIframe">
      <iframe width="100%" height="56.25%" src="https://www.youtube.com/embed/Gohb2HpYeqw" title="Bring Back the Long Lunch" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </modal>
    <div class="hero__background">
      <video autoplay muted loop class="hero__background-bg" poster="@/assets/hero/im_hero_bg.png">
          <source src="@/video/im_hero_bg.mp4" type="video/mp4">
      </video>
      <!-- <img
        :src="require('@/assets/hero/im_hero_bg.png')"
        :srcset="require('@/assets/hero/im_hero_bg.png') + ' 1x, ' + require('@/assets/hero/im_hero_bg@2x.png') + ' 2x'"
        class="desktop"/>
      <img
        :src="require('@/assets/hero/im_hero_bg_mob.jpg')"
        :srcset="require('@/assets/hero/im_hero_bg_mob.jpg') + ' 1x, ' + require('@/assets/hero/im_hero_bg_mob@2x.jpg') + ' 2x'"
        class="mob"/> -->
    </div>
    <div class="hero__logo">
      <img
        :src="require('@/assets/hero/im_logo.png')"
        :srcset="require('@/assets/hero/im_logo.png') + ' 1x, ' + require('@/assets/hero/im_logo@2x.png') + ' 2x'"/>
    </div>
    <a href="#" class="hero__cta btn" @click.prevent=openModal>
      Watch the film <img :src="require('@/assets/icons/im_icon_play.png')" />
    </a>
  </div>
</template>

<script>
import Vue from 'vue'
import VModal from 'vue-js-modal'
import { mapActions } from 'vuex'
// import VueResponsiveImage from 'vue-responsive-image'

Vue.use(VModal)

export default {
  name: 'Hero',
  props: {
    msg: String
  },
  created () {
    // On create load you resource
    this.loadResource()

    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 2500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED']),
    openModal () {
      this.$modal.show('promovid')
    },
    resizeIframe () {
      const modal = document.querySelector('.vm--container')
      const iframe = modal.querySelector('iframe')
      const width = iframe.getBoundingClientRect().width
      iframe.height = (width / 16) * 9 + 'px'
    }
  }
}
</script>

<style lang="scss">
@import './Hero.scss';
</style>
