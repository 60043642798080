<template>
  <div class="generator" v-observe-visibility="{
    callback: visibilityChanged,
    intersection: {
      threshold: 0.5,
    },
  }">
    <div class="content">
      <div class="content__left">
        <h3><span>WTF is my reason for</span><br>
        a Long Lunch TODAY<span>?</span></h3>
        <p>Financial Directors are such Coq au Vin blockers, but we can help. Here’s a million and one reasons why you need a Long Lunch now.</p>
      </div>
      <div class="content__right">
        <div class="generator__app">
          <h4 ref="wrapper">
            <span ref="excuse"></span>
            <span ref="verb" class="with"></span>
          </h4>
          <div class="generator__footer">
            <img :src="require('@/assets/im_bringback_logo.svg')"/>
          </div>
        </div>
        <a href="#" class="btn btn--blue generator__cta" @click.prevent=generate>
          Order another reason
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VModal from 'vue-js-modal'
import VueObserveVisibility from 'vue-observe-visibility'
// import fitty from 'fitty'
import { mapActions } from 'vuex'
import { excuses } from '../../json/excuses.json'

// GSAP
import { TimelineMax, gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(SplitText)

Vue.use(VModal)
Vue.use(VueObserveVisibility)

export default {
  name: 'Generator',
  props: {
    msg: String
  },
  data: () => ({
    shown: false,
    lines: Array,
    className: String
  }),
  created () {
    // On create load you resource
    this.loadResource()

    // You could have a bunch more if your view uses different endpoints
    // this.loadAnotherResource()
    // this.loadSomethingElse()
  },
  methods: {
    loadResource () {
      // Call loading which adds 1 to the loading state in the store
      this.LOADING()

      // this.LOADED()
      setTimeout(() => {
        // Call loaded which minuses 1 from the loading state in the store
        this.LOADED()
      }, 1500)
    },
    // These are the loading and loaded actions mapped from the store
    ...mapActions('loader', ['LOADING', 'LOADED']),
    getRandom (min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      if (isVisible && !this.shown) {
        this.animateIn()
        this.shown = true
      }
    },
    countLines () {
      const divHeight = this.$refs.wrapper.offsetHeight
      const lineHeight = parseInt(window.getComputedStyle(this.$refs.wrapper, null).getPropertyValue('line-height').replace('px', ''))
      const lines = divHeight / lineHeight
      return lines
    },
    generateNewExcuse () {
      const lines = []
      const targetExcuse = this.$refs.excuse
      const excuseI = this.getRandom(0, excuses.length - 1)
      // Hide
      targetExcuse.style.opacity = 0
      // Generate excuse
      const excuseText = [excuses[excuseI]]
      // Inject
      targetExcuse.innerHTML = excuseText[0]
      // Split words for animation
      lines.push(new SplitText(targetExcuse, {
        type: 'words',
        linesClass: 'split-child'
      }))
      // Split words for masking
      lines[0] = new SplitText(targetExcuse, {
        type: 'words',
        linesClass: 'split-child'
      })
      // Show
      targetExcuse.style.opacity = 1
      gsap.set(lines[0].words, { y: '100%' })
      this.lines = lines
      // Line count class (for sizing)
      const hasClass = this.$refs.wrapper.classList.contains(this.className)
      if (this.$refs.wrapper.classList && hasClass) {
        this.$refs.wrapper.classList.remove(this.className)
      }
      const lineCount = this.countLines()
      const className = 'linecount-' + parseInt(lineCount)
      this.$refs.wrapper.classList.add(className)
      this.className = className
    },
    animateIn () {
      return new Promise((resolve, reject) => {
        const tl = new TimelineMax()
        tl
          .staggerTo(this.lines[0].words, 0.5, { y: '0%' }, 0.05)
          .eventCallback('onComplete', resolve)
      })
    },
    animateOut () {
      return new Promise((resolve, reject) => {
        const tl = new TimelineMax()
        tl
          .staggerTo(this.lines[0].words, 0.2, { y: '100%' }, 0.05)
          .eventCallback('onComplete', resolve)
      })
    },
    async generate () {
      await this.animateOut()
      this.generateNewExcuse()
      await this.animateIn()
    }
  },
  mounted () {
    this.generateNewExcuse()
  }
}
</script>

<style lang="scss">
@import './Generator.scss';
</style>
