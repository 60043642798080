<template>
  <!-- Loader is shown when loading is false -->
  <div class="loader-container" :class="{ loader__loaded: !loading }">
    <div class="loader">Loading...</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    // This is the loading state coming from the loader store module
    ...mapGetters('loader', ['loading'])
  }
}
</script>

<style lang="scss">
@import './PageLoader.scss';
</style>
