<template>
  <div id="app">
    <!-- Loading comp sits at the app level so that it sits over all page layout -->
    <!-- You can also have it in the base page layout if you are using one -->
    <PageLoader />

    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Nav/>
    <Hero/>
    <Explain/>
    <ScrollFeature/>
    <JoinUs/>
    <ImageBlock imagePath="im_tabledressing.jpg"/>
    <Generator/>
    <ImageBlock imagePath="im_octopus.jpg"/>
    <div class="signthepetition"></div>
    <!-- <Petition/> -->
    <DownloadPack/>
    <div class="mob">
      <ImageBlock imagePath="im_download_image.jpg"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import PageLoader from './components/PageLoader/PageLoader.vue'
import Nav from './components/Nav/Nav.vue'
import Hero from './components/Hero/Hero.vue'
import Explain from './components/Explain/Explain.vue'
import JoinUs from './components/JoinUs/JoinUs.vue'
import ImageBlock from './components/ImageBlock/ImageBlock.vue'
import ScrollFeature from './components/ScrollFeature/ScrollFeature.vue'
import Generator from './components/Generator/Generator.vue'
// import Petition from './components/Petition/Petition.vue'
import DownloadPack from './components/DownloadPack/DownloadPack.vue'
import Footer from './components/Footer/Footer.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    PageLoader,
    Nav,
    Hero,
    Explain,
    JoinUs,
    ImageBlock,
    ScrollFeature,
    Generator,
    // Petition,
    DownloadPack,
    Footer
  }
}
</script>

<style lang="scss">
  @import 'scss/app.scss';
</style>
